import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  text-align: center;
  position: relative;
`

const Title = styled.h4`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin: 15px 0 50px 0;
  &:before,
  &:after {
    content: "";
    width: 60px;
    height: 3px;
    background: black;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &:before {
    left: -70px;
  }

  &:after {
    right: -70px;
  }

  @media only screen and (max-width: 430px) {
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }
`

type TitleComponentProps = {
  children: string
}

const TitleComponent: React.FC<TitleComponentProps> = ({ children }) => (
  <Wrapper>
    <Title>{children}</Title>
  </Wrapper>
)

export default TitleComponent
