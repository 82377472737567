import "normalize.css"

import React, { useEffect, useState } from "react"

import Categories from "components/Categories"
import { Helmet } from "react-helmet"
import { JUMBOTRON_QUERY } from "apollo/queries/jubmotron"
import Jumbotron from "components/Jumbotron"
import { PRODUCTS_CATEGORIES_QUERY } from "apollo/queries/categories"
import ProductsList from "components/ProductsList"
import { useQuery } from "@apollo/client"

const IndexPage = () => {
  const { data } = useQuery(PRODUCTS_CATEGORIES_QUERY)
  const jumbotrons = useQuery(JUMBOTRON_QUERY)
  const jumbotron = jumbotrons.data?.jumbotrons[0]

  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        xfbml: true,
        version: "v9.0",
      })
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/sk_SK/sdk/xfbml.customerchat.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marra - s dušou prírody</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Originálne ručné háčkované a pletené výrobky z prírodných materiálov ako sú merino, hodváb, angora a bavlna, ktoré dodajú každému priestoru dušu."
        />
      </Helmet>
      <Jumbotron
        title={jumbotron?.Title}
        description={jumbotron?.Description}
        image={jumbotron?.Image?.url}
        alignItems="left"
      />
      <Categories items={data?.categories} />
      <ProductsList items={data?.categories} />

      <div id="fb-root"></div>
      <script></script>
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id="105747888148158"
        theme_color="#fa3c4c"
        logged_in_greeting="Neváhaj nám napísať :-)"
        logged_out_greeting="Neváhaj nám napísať :-)"
      ></div>
    </>
  )
}

export default IndexPage
