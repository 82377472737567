import Container from "components/Container"
import React from "react"
import Title from "components/Title"
import { normalizeString } from "utils/strings"
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 40px 0;
  text-align: center;
`

const ItemsWrapper = styled.div`
  width: 100%;
  overflow: auto;
  align-items: center;
  justify-content: center;
`
const ItemImage = styled.div`
  background-size: cover;
  background-position: center;
  height: 150px;
  width: 150px;
  border-radius: 100%;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.35);
  }
`

const Item = styled.a`
  text-decoration: none;
  color: gray;
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;
  margin: 0 25px;
  &:hover {
    color: black;
  }
  &:hover ${ItemImage} {
    &:after {
      background: none;
    }
  }
`

const ItemTitle = styled.h5`
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
`

const Categories = ({ items }) => {
  if (!items) return <div></div>
  return (
    <Container>
      <Wrapper>
        <Title>Objav čisto prírodné výrobky</Title>
        <ItemsWrapper>
          {items.map(category => (
            <Item
              href={`#${normalizeString(category.Name)}`}
              key={category.Name}
            >
              <ItemImage
                style={{
                  backgroundImage: `url(${category.Image?.url})`,
                }}
              />
              <ItemTitle>{category.Name}</ItemTitle>
            </Item>
          ))}
        </ItemsWrapper>
      </Wrapper>
    </Container>
  )
}

export default Categories
