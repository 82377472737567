import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  max-width: 980px;
  margin: auto;
`

type ContainerProps = {
  children: React.ReactNode
}

const Container: React.FC<ContainerProps> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
)

export default Container
