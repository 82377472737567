import { useEffect, useState } from "react"

import Carousel from "components/Carousel"
import Container from "components/Container"
import React from "react"
import { normalizeString } from "utils/strings"
import styled from "styled-components"

const Row = styled.section`
  position: relative;
  display: flex;
  margin-bottom: 40px;
  @media only screen and (max-width: 430px) {
    flex-direction: column;
    padding: 15px;
  }
`

const Section = styled.div`
  width: 50%;
  @media only screen and (max-width: 430px) {
    width: 100%;
  }
`

const ContentWrapper = styled.div`
  padding-left: 25px;
  @media only screen and (max-width: 430px) {
    padding: 0;
    text-align: center;
  }
`

const Title = styled.h2`
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  margin: 0 0 30px 0;
  text-transform: uppercase;
  @media only screen and (max-width: 430px) {
    margin: 15px 0;
    text-align: center;
  }
`

const Description = styled.p`
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 430px) {
    margin: 15px 0;
    text-align: center;
  }
`

const SectionTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  margin: 15px 0 50px 0;
  display: inline-block;
  z-index: 10;
  background-color: pink;
  color: white;
  padding: 15px;
  @media only screen and (max-width: 430px) {
    font-size: 30px;
    margin-bottom: 15px;
  }
`

const InterestButton = styled.button`
  height: 60px;
  border: 2px solid pink;
  color: pink;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 20px 0;
  background: none;
  font-weight: 700;
  padding: 0 25px;
  &:hover {
    color: white;
    background: pink;
    cursor: pointer;
  }

  @media only screen and (max-width: 430px) {
    margin: 25px auto;
    text-align: center;
  }
`

const ProductsList = ({ items }) => {
  const [windowWidth, setWindowWidth] = useState(null)

  useEffect(() => {
    const width = window.innerWidth

    if (width) {
      setWindowWidth(width)
    }
  }, [])

  const handleInterest = name => {
    const messengerInput = document.querySelector("input")
  }

  return (
    <Container>
      {items?.map(category => (
        <React.Fragment key={category.Name}>
          <SectionTitle id={normalizeString(category.Name)}>
            {category.Name}
          </SectionTitle>
          {category?.Products?.map(product => (
            <Row key={product.Name}>
              <Section>
                <Carousel
                  height={windowWidth > 430 ? 500 : 300}
                  images={product.Images}
                />
              </Section>
              <Section>
                <ContentWrapper>
                  <Title>{product.Name}</Title>
                  <Description>{product.Description}</Description>
                  <InterestButton onClick={() => handleInterest(product.Name)}>
                    Zaujíma ma
                  </InterestButton>
                </ContentWrapper>
              </Section>
            </Row>
          ))}
        </React.Fragment>
      ))}
    </Container>
  )
}

export default ProductsList
