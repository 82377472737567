import { gql } from "@apollo/client"

export const PRODUCTS_CATEGORIES_QUERY = gql`
  query {
    categories {
      Name
      Image {
        url
      }
      Products {
        Name
        Description
        Images {
          url
        }
      }
    }
  }
`
