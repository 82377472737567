import JumbotronLogo from "images/logo-inverted.png"
import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  height: 100vh;
  background-position: center;
  background-size: cover;
  position: relative;
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.65);
  padding: 15%;
`

const Logo = styled.img`
  height: 150px;
`

const Title = styled.h1`
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  opacity: 0.8;
  margin-top: 40px;
  font-size: 40px;
`

const Description = styled.h2`
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 15px;
`

type JumbotronProps = {
  image: string
  title: string
  description: string
  alignItems: string
}

const Jumbotron: React.FC<JumbotronProps> = ({
  image,
  title,
  description,
  alignItems = "left",
}) => {
  return (
    <Wrapper style={{ backgroundImage: `url(${image})` }}>
      <ContentWrapper style={{ textAlign: alignItems }}>
        <Logo src={JumbotronLogo} />
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Jumbotron
